export const specializations = [
	{
		name: 'Hudlege',
		specialization: 'dermatologi',
		shortDescription: 'Erfarne hudleger tilbyr toppmoderne behandling for alle hudtilstander.',
		description:
			'Våre hudleger, eksperter i dermatologi, er fremstående i behandling av hudtilstander. De tilbyr presis diagnostikk og terapi for diverse hudproblemer, med fokus på forebygging og personlig pleie.'
	},
	{
		name: 'Øyelege',
		specialization: 'oftalmologi',
		shortDescription: 'Øyeleger med ekspertise i synsbehandling og øyehelse.',
		description:
			'Vårt team av øyeleger er dedikert til å bevare og forbedre din synskvalitet gjennom forebyggende omsorg og presis behandling. Spesialister på oftalmologi, de håndterer alt fra rutinesjekker til avanserte prosedyrer, sikrer at dine øyne er i de beste hender.'
	},
	{
		name: 'Øre-nese-hals lege',
		specialization: 'otorhinolaryngologi',
		shortDescription: 'Spesialister på åndedretts- og hørselsproblemer.',
		description:
			'Spesialister i otorhinolaryngologi, våre øre-nese-hals leger tilbyr helhetlig behandling for tilstander som påvirker åndedrettsfunksjoner og hørsel. De kombinerer dyptgående kunnskap med personlig omsorg for å forbedre din livskvalitet.'
	},
	{
		name: 'Hjertelege',
		shortDescription: 'Kardiologer fokuserer på omfattende hjertehelse og behandling.',
		specialization: 'kardiologi',
		description:
			'Våre hjerteleger, med spesialisering i kardiologi, er ledende eksperter på hjertehelse. De tilbyr avansert diagnostikk og behandling for et bredt spekter av hjertesykdommer, med et sterkt fokus på forebygging og rehabilitering.'
	},
	{
		name: 'Ortoped',
		shortDescription: 'Ortopeder tilbyr behandling for muskel- og skjelettlidelser.',
		specialization: 'ortopedisk kirurgi',
		description:
			'Spesialister på bevegelsesapparatets helse, våre ortopeder tilbyr avansert behandling for skader og tilstander i muskler, bein, og ledd. De streber etter å gjenopprette funksjon og redusere smerte med både kirurgiske og ikke-kirurgiske tilnærminger.'
	},
	{
		name: 'Gynekolog',
		shortDescription: 'Gynekologer spesialisert på kvinnehelse og reproduktiv omsorg.',
		specialization: 'gynekologi',
		description:
			'Våre gynekologer er eksperter på kvinnehelse, tilbyr en rekke tjenester fra forebyggende omsorg til reproduktiv helse. De skaper et støttende og diskret miljø for kvinner i alle livsfaser.'
	},
	{
		name: 'Nevrolog',
		shortDescription: 'Nevrologer behandler tilstander relatert til nervesystemet.',
		specialization: 'nevrologi',
		description:
			'Med spisskompetanse innen nevrologi, adresserer våre nevrologer komplekse tilstander som påvirker nervesystemet. De anvender nyeste forskning og teknologi for å tilby behandling som kan forbedre livskvaliteten for pasienter med nevrologiske utfordringer.'
	},
	{
		name: 'Psykolog',
		shortDescription: 'Psykologer støtter mental helse gjennom terapi og rådgivning.',
		specialization: 'psykologi',
		description:
			'Våre psykologer tilbyr en trygg havn for mental helse, arbeider for å forstå og løse psykologiske utfordringer gjennom samtaleterapi og evidensbaserte tilnærminger. De er dedikert til å fremme psykisk velvære og personlig vekst.'
	},
	{
		name: 'Tannlege',
		shortDescription: 'Tannleger sørger for omfattende munn- og tannhelse.',
		specialization: 'odontologi',
		description:
			'Dedikerte til din munnhelse, våre tannleger anvender det siste innen odontologi for å sikre optimal omsorg. Fra forebyggende vedlikehold til avanserte kosmetiske og restaurerende prosedyrer, sikrer de at ditt smil er både sunt og strålende.'
	}
]

export const allDoctors = [
	{
		specialization: 'dermatologi',
		doctors: [
			{
				id: 0,
				name: 'Emilie Haugen',
				bio: 'Emilie Haugen er en fremstående dermatolog med en dyp forståelse for hudens helse. Med en omfattende bakgrunn i dermatologiske lidelser, tilbyr hun behandlinger som er både innovative og skånsomme. Emilie er dedikert til å tilby personlig tilpassede omsorgsplaner, og hennes praksis er kjent for å bruke de siste innen teknologisk fremskritt for å oppnå best mulige resultater. Hun verdsetter viktigheten av å lytte til hver pasients unike bekymringer og arbeider utrettelig for å forbedre deres hudhelse og selvtillit.',
				image: '/doctors/dermatologi/Emilie Haugen.webp'
			},
			{
				id: 1,
				name: 'Jonas Kristoffersen',
				bio: 'Jonas Kristoffersen er en ledende ekspert innen dermatologi, spesialisert på behandling av komplekse hudtilstander. Hans tilnærming kombinerer dyptgående forskning med praktisk erfaring, noe som resulterer i avanserte, skreddersydde behandlingsplaner. Jonas er lidenskapelig opptatt av å fremme en helhetlig forståelse av hudhelse, og hans praksis er et trygt sted for pasienter som søker den nyeste innen dermatologisk omsorg. Han er anerkjent for sitt arbeid med å utvikle nye metoder for å behandle hudsykdommer og for sitt engasjement i å veilede pasienter gjennom deres behandlingsreise.',
				image: '/doctors/dermatologi/Jonas Kristoffersen.webp'
			},
			{
				id: 2,
				name: 'Sara Lund',
				bio: 'Sara Lund er en anerkjent dermatolog som spesialiserer seg på både voksne og barns hudhelse. Med en lidenskap for å hjelpe pasienter med å oppnå og opprettholde sunn hud, tilbyr Sara en rekke behandlinger fra medisinsk dermatologi til estetiske prosedyrer. Hennes praksis legger vekt på en integrert tilnærming til hudpleie, kombinerer det beste fra tradisjonell medisin med innovative behandlinger. Sara er dypt engasjert i sine pasienters velvære og streber etter å tilby en forståelsesfull og støttende omsorg.',
				image: '/doctors/dermatologi/Sara Lund.webp'
			},
			{
				id: 3,
				name: 'Mikkel Johansen',
				bio: 'Mikkel Johansen er en dyktig dermatolog kjent for sin ekspertise i behandlingen av akutte og kroniske hudsykdommer. Med en tilnærming som er både omfattende og tilpasset, fokuserer Mikkel på å adressere roten til hudproblemer samtidig som han forbedrer den generelle hudhelsen. Hans praksis er kjent for å være i forkant med bruk av banebrytende teknikker og behandlinger, og han er dedikert til å tilby sine pasienter den høyeste standarden av dermatologisk omsorg. Mikkel verdsetter betydningen av utdanning og forebygging i hudhelse og er en pålitelig ressurs for sine pasienter.',
				image: '/doctors/dermatologi/Mikkel Johansen.webp'
			}
		]
	},
	{
		specialization: 'oftalmologi',
		doctors: [
			{
				id: 4,
				name: 'Lena Nordby',
				bio: 'Lena Nordby er en erfaren øyelege som spesialiserer seg på å bevare og forbedre synet gjennom avansert oftalmologisk omsorg. Med en detaljorientert tilnærming til hver pasient, integrerer Lena den nyeste teknologien og forskningsbaserte behandlinger for å håndtere et bredt spekter av synsproblemer. Hennes praksis er dedikert til å tilby omfattende øyeundersøkelser, diagnose og behandling av øyesykdommer, og personlig tilpassede synskorreksjoner. Lenas lidenskap for øyehelse og hennes engasjement for pasientopplæring sikrer at hver pasient forlater med en dypere forståelse av deres synshelse.',
				image: '/doctors/oftalmologi/Lena Nordby.webp'
			},
			{
				id: 5,
				name: 'Erik Moe',
				bio: 'Erik Moe er en øyelege kjent for sin ekspertise i oftalmologi og for sitt arbeid med laserøyebehandlinger. Med et mål om å tilby pasientene frihet fra briller og kontaktlinser, bruker Erik avansert laserteknologi for å korrigere refraktive feil. Hans praksis tilbyr også omfattende omsorg for øyesykdommer, inkludert glaukom og diabetisk retinopati. Eriks tilnærming er basert på personlig omsorg og skreddersydde løsninger, sikrer at hver behandling er tilpasset individets unike synsbehov.',
				image: '/doctors/oftalmologi/Erik Moe.webp'
			},
			{
				id: 6,
				name: 'Kari Foss',
				bio: 'Kari Foss er en fremstående øyelege med en spesiell interesse for barns øyehelse og strabisme (skjeling). Med en kombinasjon av høy faglig kompetanse og en omsorgsfull tilnærming, tilbyr Kari avanserte diagnostiske tjenester og behandlinger for å korrigere synsproblemer hos barn. Hennes praksis er utstyrt for å tilby en trygg og barnevennlig atmosfære, og hun er dedikert til å arbeide tett med familier for å sikre optimal øyehelse og utvikling hos de yngste pasientene.',
				image: '/doctors/oftalmologi/Kari Foss.webp'
			},
			{
				id: 7,
				name: 'Anders Holm',
				bio: 'Anders Holm er en dyktig øyelege med omfattende erfaring i behandling av aldersrelaterte øyesykdommer, inkludert aldersrelatert maculadegenerasjon og katarakt. Gjennom en tilnærming som fokuserer på forebygging og tidlig intervensjon, sikrer Anders at pasientene mottar den mest effektive omsorgen for å bevare synet. Hans praksis legger stor vekt på å bruke state-of-the-art teknologi for diagnostikk og behandling, og han er lidenskapelig opptatt av å gi pasientene tilgang til de nyeste behandlingsalternativene.',
				image: '/doctors/oftalmologi/Anders Holm.webp'
			}
		]
	},
	{
		specialization: 'otorhinolaryngologi',
		doctors: [
			{
				id: 8,
				name: 'Hanne Eriksen',
				bio: 'Hanne Eriksen er en ekspert øre-nese-hals lege som spesialiserer seg på omfattende behandlinger for en rekke tilstander, inkludert kronisk bihulebetennelse, hørselstap og stemmeproblemer. Med en dyp forståelse for hvordan disse tilstandene påvirker livskvaliteten, tilbyr Hanne innovative og minimert invasive behandlingsmetoder. Hennes tilnærming er personlig, og hun streber etter å forstå og adressere hver pasients unike behov gjennom avansert diagnostikk og skreddersydd behandlingsplanlegging.',
				image: '/doctors/otorhinolaryngologi/Hanne Eriksen.webp'
			},
			{
				id: 9,
				name: 'Erik Solheim',
				bio: 'Erik Solheim er en fremragende øre-nese-hals spesialist med en spesiell interesse for pediatrisk otorhinolaryngologi. Erik er dedikert til å tilby den yngste pasientgruppen den mest omsorgsfulle og effektive behandlingen. Med en kombinasjon av avanserte teknikker og en varm, barn-vennlig tilnærming, sikrer han at barna og deres familier føler seg trygge og støttet gjennom hele behandlingsprosessen. Eriks praksis fokuserer på å løse alt fra vanlige øreinfeksjoner til mer komplekse tilstander som krever kirurgi.',
				image: '/doctors/otorhinolaryngologi/Erik Solheim.webp'
			},
			{
				id: 10,
				name: 'Maria Larsen',
				bio: 'Maria Larsen er en dyktig øre-nese-hals lege kjent for sin ekspertise i behandlingen av snorkeproblemer og søvnapné. Gjennom en kombinasjon av livsstilsendringer og avanserte behandlingsalternativer, arbeider Maria for å forbedre pasientenes søvnkvalitet og generelle helse. Hennes praksis er utstyrt med det siste innen diagnostisk teknologi, noe som gjør det mulig for henne å tilby skreddersydde løsninger som adresserer de spesifikke behovene til hver enkelt pasient.',
				image: '/doctors/otorhinolaryngologi/Maria Larsen.webp'
			},
			{
				id: 11,
				name: 'Lars Kristoffersen',
				bio: "Lars Kristoffersen er en anerkjent øre-nese-hals spesialist med omfattende erfaring i behandling av hode- og nakkekreft. Lars' tilnærming til omsorg er helhetlig, og han samarbeider tett med et team av onkologer og radiologer for å tilby pasientene den mest effektive, multidisiplinære behandlingen. Hans dedikasjon til forskning og anvendelse av de nyeste behandlingsmetodene sikrer at hans pasienter mottar den mest avanserte omsorgen tilgjengelig.",
				image: '/doctors/otorhinolaryngologi/Lars Kristoffersen.webp'
			}
		]
	},
	{
		specialization: 'kardiologi',
		doctors: [
			{
				id: 12,
				name: 'Andreas Bjørnstad',
				bio: "Andreas Bjørnstad er en ledende kardiolog som spesialiserer seg på diagnostikk og behandling av hjertesykdommer. Med en lidenskap for forebyggende kardiologi, fokuserer Andreas på risikofaktorhåndtering og livsstilsendringer for å forbedre hjertehelsen. Hans praksis tilbyr det nyeste innen ikke-invasive tester og behandlinger, inkludert ekkokardiografi og stress-testing. Andreas' tilnærming er pasient-sentrert, og han er dedikert til å tilby personlig og medfølende omsorg.",
				image: '/doctors/kardiologi/Andreas Bjørnstad.webp'
			},
			{
				id: 13,
				name: 'Birgitte Fossum',
				bio: 'Birgitte Fossum er en kardiolog med spesialkompetanse i intervensjonskardiologi. Hun er ekspert på kateterbaserte behandlinger for koronar arteriesykdom, inkludert angioplastikk og stentplassering. Birgittes mål er å gjenopprette blodstrømmen til hjertet og forbedre pasientenes livskvalitet gjennom minimert invasive prosedyrer. Hennes praksis benytter seg av banebrytende teknologi for å sikre de beste resultatene, og hun er kjent for sitt engasjement for pasientomsorg og utdanning.',
				image: '/doctors/kardiologi/Birgitte Fossum.webp'
			},
			{
				id: 14,
				name: 'Christian Dahl',
				bio: 'Christian Dahl er en anerkjent kardiolog med en spesiell interesse for elektrofysiologi og arytmi-behandling. Med avansert trening i hjertearytmier, tilbyr Christian diagnostikk og behandling for pasienter med uregelmessige hjerterytmer, inkludert ablasjonsbehandling og pacemaker-implantasjoner. Hans tilnærming er basert på en grundig forståelse av hvert tilfelle, og han er dedikert til å bruke sin ekspertise til å forbedre pasientenes helse og velvære.',
				image: '/doctors/kardiologi/Christian Dahl.webp'
			},
			{
				id: 15,
				name: 'Dina Solberg',
				bio: 'Dina Solberg er en fremragende kardiolog som fokuserer på hjertesviktbehandling og kardiovaskulær genetikk. Dinas arbeid kombinerer klinisk praksis med forskning for å forstå de genetiske faktorene som bidrar til hjertesykdom. Hun tilbyr omfattende omsorg for pasienter med kronisk hjertesvikt, og arbeider for å integrere nye, evidensbaserte behandlinger for å forbedre pasientenes utfall og livskvalitet.',
				image: '/doctors/kardiologi/Dina Solberg.webp'
			}
		]
	},
	{
		specialization: 'ortopedisk kirurgi',
		doctors: [
			{
				id: 16,
				name: 'Espen Haugland',
				bio: 'Espen Haugland er en ledende ortopedisk kirurg med spesialisering på behandling av sportsrelaterte skader og artrose. Med en tilnærming som fokuserer på både kirurgiske og ikke-kirurgiske behandlingsmetoder, arbeider Espen for å gjenopprette funksjon og redusere smerte hos sine pasienter. Hans praksis benytter den nyeste teknologien for å tilby tilpassede behandlingsplaner, inkludert artroskopisk kirurgi og felleserstatning, sikrer optimal utvinning og rehabilitering.',
				image: '/doctors/ortopedisk kirurgi/Espen Haugland.webp'
			},
			{
				id: 17,
				name: 'Frida Johansen',
				bio: 'Frida Johansen er en ortopedisk kirurg kjent for sin ekspertise i ryggkirurgi og behandling av spinal lidelser. Med en dyp forståelse for kompleksiteten i ryggradens anatomi, tilbyr Frida avanserte kirurgiske løsninger for å adressere alt fra kronisk ryggsmerter til degenerative disksykdommer. Hennes mål er å forbedre pasientenes funksjon og livskvalitet gjennom skreddersydde behandlingsplaner og omfattende rehabiliteringsprogrammer.',
				image: '/doctors/ortopedisk kirurgi/Frida Johansen.webp'
			},
			{
				id: 18,
				name: 'Geir Lund',
				bio: 'Geir Lund er en fremragende ortopedisk kirurg med en spesiell interesse for hånd- og mikrokirurgi. Geirs ekspertise omfatter behandling av komplekse håndskader, nervekomprimeringssyndromer og mikrovaskulær rekonstruksjon. Med et mål om å gjenopprette funksjon og estetikk, tilbyr Geir sine pasienter den høyeste standarden av omsorg, kombinert med den nyeste teknologien og kirurgiske teknikker.',
				image: '/doctors/ortopedisk kirurgi/Geir Lund.webp'
			},
			{
				id: 19,
				name: 'Hilde Foss',
				bio: 'Hilde Foss er en dyktig ortopedisk kirurg med en omfattende bakgrunn i felleserstatningskirurgi, spesielt knær og hofter. Hilde fokuserer på å tilby pasientene en ny sjanse til et aktivt liv gjennom total og delvis fellesutskifting. Hennes praksis legger stor vekt på en individuell tilnærming til hver pasient, sikrer grundig preoperativ evaluering og tilpasset postoperativ rehabilitering for å oppnå de beste mulige resultatene.',
				image: '/doctors/ortopedisk kirurgi/Hilde Foss.webp'
			}
		]
	},
	{
		specialization: 'gynekologi',
		doctors: [
			{
				id: 20,
				name: 'Ingrid Moe',
				bio: 'Ingrid Moe er en erfaren gynekolog som tilbyr omfattende omsorg for kvinners helse gjennom alle livsfaser. Med en spesiell interesse for reproduktiv helse, tilbyr Ingrid alt fra forebyggende omsorg og screening til fertilitetsrådgivning og behandling. Hennes tilnærming er pasient-sentrert, og hun er dedikert til å skape et støttende miljø der kvinner kan motta omsorg tilpasset deres unike behov.',
				image: '/doctors/gynekologi/Ingrid Moe.webp'
			},
			{
				id: 21,
				name: 'Johan Berg',
				bio: 'Johan Berg er en gynekolog spesialisert på minimalt invasive kirurgiske teknikker. Johan er kjent for sin ekspertise i laparoskopisk kirurgi, noe som tilbyr pasientene kortere utvinningstid og mindre postoperative smerter. Hans praksis fokuserer på behandling av endometriose, fibroider og ovariecyster, og han er dedikert til å tilby de nyeste og mest effektive behandlingsalternativene.',
				image: '/doctors/gynekologi/Johan Berg.webp'
			},
			{
				id: 22,
				name: 'Kari Hagen',
				bio: 'Kari Hagen er en dyktig gynekolog med en dyp forståelse for menopausale helseutfordringer. Kari tilbyr en rekke behandlingsalternativer for å håndtere symptomer på overgangsalderen, inkludert hormonbehandling og livsstilsrådgivning. Hennes tilnærming er helhetlig, og hun arbeider tett med hver pasient for å utvikle skreddersydde omsorgsplaner som adresserer både fysiske og emosjonelle aspekter av menopause.',
				image: '/doctors/gynekologi/Kari Hagen.webp'
			},
			{
				id: 23,
				name: 'Lene Dahl',
				bio: 'Lene Dahl er en fremstående gynekolog med en spesiell interesse for kvinners seksuelle helse. Med en omfattende bakgrunn i både medisinsk og psykologisk omsorg, tilbyr Lene en helhetlig tilnærming til behandling av seksuelle dysfunksjoner. Hennes praksis er et trygt og konfidensielt rom der kvinner kan søke råd og behandling for et bredt spekter av seksuelle helseproblemer.',
				image: '/doctors/gynekologi/Lene Dahl.webp'
			}
		]
	},
	{
		specialization: 'nevrologi',
		doctors: [
			{
				id: 24,
				name: 'Magnus Svendsen',
				bio: 'Magnus Svendsen er en anerkjent nevrolog med spesialisering på behandling av nevrodegenerative sykdommer, som Alzheimers og Parkinsons. Med en lidenskap for å forbedre livskvaliteten for sine pasienter, integrerer Magnus de nyeste forskningsfunnene med individuell omsorg. Hans tilnærming er grundig og pasient-sentrert, med fokus på å tilby støtte og avanserte behandlingsalternativer for å håndtere symptomer og forsinke sykdomsprogresjon.',
				image: '/doctors/nevrologi/Magnus Svendsen.webp'
			},
			{
				id: 25,
				name: 'Nina Solberg',
				bio: 'Nina Solberg er en dyktig nevrolog kjent for sin ekspertise i epilepsi og anfallsbehandling. Nina bruker avansert diagnostisk teknologi for å nøyaktig identifisere anfallskilder og tilbyr skreddersydde behandlingsplaner som kan inkludere medikamentell behandling, kostholdsendringer og i noen tilfeller kirurgi. Hennes mål er å oppnå optimal anfallskontroll og forbedre pasientenes uavhengighet og livskvalitet.',
				image: '/doctors/nevrologi/Nina Solberg.webp'
			},
			{
				id: 26,
				name: 'Ole Kristiansen',
				bio: 'Ole Kristiansen er en fremragende nevrolog med en spesiell interesse for hodepine og migrene. Ole tilbyr en omfattende evaluering for å identifisere utløsende faktorer og utvikler individuelle behandlingsstrategier som kan inkludere medikamenter, livsstilsendringer og forebyggende tiltak. Hans tilnærming er basert på en forståelse av hodepinens komplekse natur og et ønske om å forbedre pasientens velvære.',
				image: '/doctors/nevrologi/Ole Kristiansen.webp'
			},
			{
				id: 27,
				name: 'Petter Larsen',
				bio: 'Petter Larsen er en nevrolog med spesialkompetanse i multippel sklerose (MS) og autoimmune nevrologiske lidelser. Med en dyptgående kunnskap om immunsystemets rolle i nevrologi, tilbyr Petter innovative behandlingsalternativer som målrettet immunmodulerende terapier. Hans praksis er dedikert til å tilby pasientene tilgang til de nyeste behandlingene og støtte gjennom deres sykdomsforløp.',
				image: '/doctors/nevrologi/Petter Larsen.webp'
			}
		]
	},
	{
		specialization: 'psykologi',
		doctors: [
			{
				id: 28,
				name: 'Silje Nordvik',
				bio: 'Silje Nordvik er en erfaren psykolog som spesialiserer seg på kognitiv atferdsterapi (CBT) for angst og depresjon. Med en pragmatisk og løsningsorientert tilnærming, arbeider Silje for å hjelpe pasientene med å identifisere og endre negative tankemønstre og atferd som bidrar til deres psykiske helseproblemer. Hennes praksis tilbyr et støttende og ikke-dømmende miljø der pasienter kan lære og vokse.',
				image: '/doctors/psykologi/Silje Nordvik.webp'
			},
			{
				id: 29,
				name: 'Thomas Rasmussen',
				bio: 'Thomas Rasmussen er en dyktig psykolog med en spesiell interesse for parterapi og familieterapi. Thomas tror på betydningen av relasjoner for individuell velvære og tilbyr kommunikasjonsstrategier og konfliktløsningsteknikker for å forbedre familiens dynamikk og parforhold. Hans tilnærming er samarbeidsvillig og styrkebasert, fokuserer på å bygge resiliens og positive interaksjoner.',
				image: '/doctors/psykologi/Thomas Rasmussen.webp'
			},
			{
				id: 30,
				name: 'Vibeke Jensen',
				bio: 'Vibeke Jensen er en psykolog som fokuserer på behandling av traumer og posttraumatisk stresslidelse (PTSD) gjennom EMDR (Eye Movement Desensitization and Reprocessing) og traumeinformert omsorg. Vibeke har en dyptgående forståelse for traumets påvirkning på psyken og tilbyr en sensitiv og helhetlig tilnærming for å støtte helbredelsesprosessen. Hennes praksis er et trygt sted for å utforske og bearbeide smertefulle erfaringer.',
				image: '/doctors/psykologi/Vibeke Jensen.webp'
			},
			{
				id: 31,
				name: 'William Thorsen',
				bio: 'William Thorsen er en psykolog med omfattende erfaring i arbeid med ungdom og unge voksne som sliter med identitetsproblemer, selvbilde og sosial angst. Gjennom individuell terapi og gruppebehandlingsprogrammer, tilbyr William støttende strategier for å fremme selvaksept og personlig vekst. Hans praksis legger vekt på å skape et inkluderende og forståelsesfullt miljø der unge mennesker kan føle seg hørt og validert.',
				image: '/doctors/psykologi/William Thorsen.webp'
			}
		]
	},

	{
		specialization: 'odontologi',
		doctors: [
			{
				id: 32,
				name: 'Olav Jensen',
				bio: 'Møt Olav Jensen, en ledende tannlege innen odontologi, kjent for sin eksepsjonelle dyktighet og omsorg for pasientene. Med en lidenskap for munnhelse, benytter Olav seg av de nyeste teknologiene og behandlingsmetodene for å sikre at hver pasient oppnår det beste resultatet. Hans praksis fokuserer på forebygging, kosmetiske forbedringer og restaurerende tannbehandlinger, alt i et trygt og innbydende miljø. Olavs tilnærming er personlig; han lytter nøye til dine behov og skreddersyr behandlinger for å matche dine ønsker. Enten det er en rutinemessig tannsjekk, komplekse tannbehandlinger, eller estetiske forbedringer, streber Olav etter å overgå forventningene og sikre at alle forlater med et strålende smil. Hans engasjement for kontinuerlig utdanning og forbedring betyr at du alltid vil motta den mest oppdaterte omsorgen. Velg Olav Jensen for en tannlegeopplevelse som prioriterer din komfort, helse og tilfredshet.',
				image: '/doctors/odontologi/Olav Jensen.webp'
			},
			{
				id: 33,
				name: 'Anna Solberg',
				bio: 'Anna Solberg er en anerkjent tannlege med spesialisering i odontologi, som har dedikert sin karriere til å tilby omsorgsfull og effektiv tannbehandling. Med en dyp forståelse for viktigheten av oral helse, benytter Anna avanserte teknikker for å behandle og forebygge tannsykdommer. Hennes praksis legger stor vekt på å skape en avslappet atmosfære der pasienter kan føle seg trygge og ivaretatt. Anna er spesielt dyktig i estetisk tannpleie, og hjelper pasientene med å oppnå sine drømmesmil. Gjennom skreddersydde behandlingsplaner og personlig oppfølging, sikrer hun at hver pasient får den beste mulige pleien. Velg Anna Solberg for en tannlege som går den ekstra milen for ditt smil og velvære.',
				image: '/doctors/odontologi/Anna Solberg.webp'
			},
			{
				id: 34,
				name: 'Lars Pettersen',
				bio: 'Lars Pettersen er en fremragende tannlege med spisskompetanse i odontologi, kjent for sin innovative tilnærming til tannbehandling. Lars er lidenskapelig opptatt av å integrere ny teknologi og forskningsbaserte metoder for å forbedre pasientenes munnhelse. Hans klinikk tilbyr et bredt spekter av tjenester, fra forebyggende vedlikehold til komplekse kosmetiske og restaurerende inngrep. Med en filosofi om at god munnhelse er nøkkelen til generell velvære, arbeider Lars tett med pasientene for å utvikle behandlingsplaner som reflekterer deres individuelle behov. Velg Lars Pettersen for en tannlege som setter din helse og tilfredshet først, og som alltid er oppdatert på de siste fremskrittene innen odontologi.',
				image: '/doctors/odontologi/Lars Pettersen.webp'
			},
			{
				id: 35,
				name: 'Sofia Kristiansen',
				bio: 'Sofia Kristiansen er en dyktig tannlege spesialisert i odontologi, med en varm og innbydende tilnærming til pasientbehandling. Hennes engasjement for pasientenes velvære går hånd i hånd med hennes ekspertise i moderne tannpleieteknikker. Sofia fokuserer på å bygge langvarige relasjoner med sine pasienter, og tilbyr tilpassede løsninger som fremmer både estetikk og funksjonalitet. Hennes praksis er utstyrt med det siste innen tannpleieteknologi, og hun er dedikert til å tilby en smertefri og behagelig opplevelse. Med en pasjon for kontinuerlig læring, er Sofia Kristiansen tannlegen som sikrer at du mottar den mest omfattende og forsiktige omsorgen for ditt smil.',
				image: '/doctors/odontologi/Sofia Kristiansen.webp'
			}
		]
	}
]

export const generalInformation = {
	shortDescription:
		'Våre spesialister tilbyr ledende behandlinger innen dermatologi, kardiologi, oftalmologi, otorhinolaryngologi, ortopedisk kirurgi, gynekologi, nevrologi, og psykologi. Vi fokuserer på toppmoderne diagnostikk og terapi for et bredt spekter av tilstander, sikter mot optimal helse og velvære for alle pasienter.',
	imageUrl: 'generic/doctors.webp',
	description:
		'Hos oss arbeider et team av høyt kvalifiserte spesialister innenfor et mangfold av medisinske felt, inkludert dermatologi, kardiologi, oftalmologi, otorhinolaryngologi, ortopedisk kirurgi, gynekologi, nevrologi, og psykologi. Våre eksperter er dedikert til å tilby presis diagnostikk og effektive behandlingsplaner tilpasset individuelle behov. Med et sterkt fokus på forebyggende helse, personlig pleie og den nyeste medisinske forskningen, er målet vårt å forbedre livskvaliteten gjennom omfattende omsorg og støtte. Vår tilnærming kombinerer innovativ teknologi med medfølende pasientomsorg for å adressere både akutte og kroniske tilstander, fremme langsiktig helse og velvære.'
}
